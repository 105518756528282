.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50%;
    flex-direction: column;
}
.container h1, .container h2, .container h3 {
    font-weight: 500;
}
.container h1 {
    margin: 0;
    padding: 0;
    font-size: 2.6rem;
    color: #D53539;
}
.container h2 {
    font-size: 1.6rem;
}
.container h3 {
    font-weight: 400;
    font-size: 1.1rem;
    color: #848EA0;

}