@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #22242E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  padding: 10px 10px;
  background-color: rgb(5, 96, 246);
  color: white;
  border: none;
  border-radius: 16px;
}
.rupee {
  font-family: 'Roboto', sans-serif;;
}
a {
  text-decoration: none;
  color: #22242E;
}
.pageLoading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 80px;
  height: 80px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #6366f1;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loaderDisplay {
  display: none;
}
 
.overflow-x-y{
  overflow: scroll;
}

.selectTestHeight {
  max-height: 500px;
}

.modelFrame {
  width: 75% !important;
  margin-left: 20% !important;
}

.searchbx {
  margin-top: 5px;
    border: 1px solid #00000045;
    border-radius: 6px;
    padding-left: 7px;
    width: 50%;
}
