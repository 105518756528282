@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.main-page {
  /* margin-top: 65px; */
  width: 100vw;
  margin-left: 4vw;
  min-height: 100vh;
  background-color: #FCFDFE;
}

@media (max-width: 768px) {
  .main-page {
    display: block;
  }
}

.Signin_main__1QR1u {
    background-color: #f2f2f2;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Signin_login__1Hyeh {
    padding: 30px 50px !important;
    background-color: #fff;
    border-radius: 20px !important;
    text-align: center;
}
.Signin_login__1Hyeh h2 {
    font-weight: 500;
    font-size: 2.4rem;
}
.Signin_login__1Hyeh h3 {
    font-weight: 400;
    color: #626264;

}
::-webkit-input-placeholder{
    color: #848EA0;
}
::placeholder{
    color: #848EA0;
}
.Signin_formInput__1cg5r {
    color: #22242E;
    display: block;
    min-width: 400px;
    padding: 10px 4px;
    margin: 18px auto;
    border: none;
    border-bottom: 1px solid #EAEDF0;
    outline: none;
    font-size: 1.3rem;
}
.Signin_checkbox__m3OSX {
    display: flex;
    align-items: center;
    color: #848EA0;
    margin: 20px 0;
}
input[type='checkbox'] {
    margin-right: 8px;
    color: #848EA0;
}
.Signin_login__1Hyeh a{
    color: rgb(82, 82, 82);
    text-decoration: none;
    cursor: pointer;
}
.Signin_login__1Hyeh a:hover {
    color: rgb(34, 34, 34);
}
.Signin_login__1Hyeh button[type='submit'] {
    margin-top: 20px;
}
.Signin_password__Cy_bt {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    position: relative;
    margin: 0 auto;
}
.Signin_password__Cy_bt button {
    font-size: 1.5rem;
    background: none;
    color: rgb(82, 82, 82);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 18px;
}
.Signin_password__Cy_bt button:hover {
    color: #22242E;
}

.Navbar_topnav__3Af9b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.Navbar_userName__1RGWS  {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Navbar_userName__1RGWS img {
  margin: 0;
  padding: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
} 
.Navbar_userName__1RGWS h3 {
  margin-left: 14px;
  margin-right: 14px;
  font-size: 1.2rem;
  font-weight: 500;

}
.Navbar_userName__1RGWS div {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.Navbar_projectName__3L9eO {
  font-weight: 600;
  display: flex !important;
  align-items: center;
}
.Navbar_projecName__mvf-Q i {
  color: rgb(0, 115, 255);
  margin-left: 12px;

}


.Navbar_box__av6tq {
  position: relative;
  margin-left: 80px;
  min-width: 350px;
}
.Navbar_dropdownIcon__3ImME {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
  
}
.Navbar_dropdownIcon__3ImME i {
  color: #0A60BD
}
.Navbar_sidebar__1W400 {
width: 60px;
min-height: 100vh;
position:fixed;
z-index: 100;
background-color: #fff;
-webkit-filter: drop-shadow(5px 5px 10px rgb(236, 245, 255));
        filter: drop-shadow(5px 5px 10px rgb(236, 245, 255));
overflow:hidden;
transition: all .4s;
display: flex;
flex-direction: column;
}
.Navbar_sidebar__1W400:hover {
width: 260px
}
.Navbar_sidebar__1W400:hover .Navbar_menuIcon__35GKg img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Navbar_sidebar__1W400 ul {
padding: 0px 6px;
}
.Navbar_navLink__1J5_h {
opacity: 0 !important;
min-width: 300px !important;
cursor: pointer !important;
padding: 10px;
font-weight: 400 !important;
font-size: 1.1rem !important;
color: #22242E !important;
}
.Navbar_sidebar__1W400:hover .Navbar_navLink__1J5_h {
opacity: 1 !important;
color: white;
}
.Navbar_sidebar__1W400 li {
transition: all .3s;
padding: 0px 10px;
display: flex;
align-items: center !important;
margin-bottom: 20px;
border-radius: 6px;
}
.Navbar_sidebar__1W400 li:hover {
background-color: #c5ccff;
}
.Navbar_nav__items__26pCG {
  margin: 0 !important;
  margin: 6px 0 !important;
}
.Navbar_nav__items__26pCG img {
  width: 30px;
  height: 30px;
  min-width: 30px;
  vertical-align:middle;
  object-fit: contain;
}
.Navbar_dropdownArrow__1ZeUm {
  border: none !important;
}
.Navbar_dropdown__3S8CC {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0px 10px !important;
  background-color: #fff !important;
  color: #22242E !important;
  outline: none !important;
  cursor: pointer !important;
  border: none !important;
  border-radius: 0 !important;
}
.Navbar_dropdownMenu__3nW1b {
  border: 2px solid #d9e1e7 !important;
  margin-top: 8px !important;
  border-radius: 10px !important;

}
.Navbar_user__23Or9{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Navbar_user__23Or9 li {
  list-style: none;
  margin-right: 30px;
  font-size: 1.2rem;
  padding-right: 30px;
}
.Navbar_user__23Or9 li img {
  margin-right: 10px;
}
.Navbar_divider__31tnA {
  margin: 0px 25px;
  background-color: #EAEDF0;
  min-width: 2px;
  min-height: 40px;
  border-radius: 4px;
}
.Navbar_menuIcon__35GKg div {
  position: absolute;
  right: 10px;
  top: 20px;
}
.Navbar_menuIcon__35GKg img {
  width: 40px;
}
.Navbar_menuIcon__35GKg {
  position: relative;
}


.NotFound_container__1v_WT {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50%;
    flex-direction: column;
}
.NotFound_container__1v_WT h1, .NotFound_container__1v_WT h2, .NotFound_container__1v_WT h3 {
    font-weight: 500;
}
.NotFound_container__1v_WT h1 {
    margin: 0;
    padding: 0;
    font-size: 2.6rem;
    color: #D53539;
}
.NotFound_container__1v_WT h2 {
    font-size: 1.6rem;
}
.NotFound_container__1v_WT h3 {
    font-weight: 400;
    font-size: 1.1rem;
    color: #848EA0;

}
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #22242E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  padding: 10px 10px;
  background-color: rgb(5, 96, 246);
  color: white;
  border: none;
  border-radius: 16px;
}
.rupee {
  font-family: 'Roboto', sans-serif;;
}
a {
  text-decoration: none;
  color: #22242E;
}
.pageLoading {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 80px;
  height: 80px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #6366f1;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.loaderDisplay {
  display: none;
}
 
.overflow-x-y{
  overflow: scroll;
}

.selectTestHeight {
  max-height: 500px;
}

.modelFrame {
  width: 75% !important;
  margin-left: 20% !important;
}

.searchbx {
  margin-top: 5px;
    border: 1px solid #00000045;
    border-radius: 6px;
    padding-left: 7px;
    width: 50%;
}

